"use client"

import { Button } from "~/components/ui/button"
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PlusCircle, User, Spinner, Tag, Check, X } from "@phosphor-icons/react/dist/ssr"
import { toast } from 'sonner'
import { PopoverContent, Popover, PopoverTrigger } from "./ui/popover";
import { Tag as TagType } from '~/server/db/schema';
import { hashStringToColor, getDarkerColor } from "~/lib/utils";
import { motion, AnimatePresence } from "framer-motion";
import Link from 'next/link';
import { signOut } from "~/lib/authActions";
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import Image from 'next/image';

import { Dock as DockWrapper, DockIcon } from "~/components/magicui/dock";

export function Dock({user: user, signInUrl}: any) {
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const streamRef = useRef<MediaStream | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceNodeRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const [guestId, setGuestId] = useState<string | null>(null);
  const audioWorkletNodeRef = useRef<AudioWorkletNode | null>(null);
  const audioBufferRef = useRef<Float32Array[]>([]);
  const [canStop, setCanStop] = useState(false);

  const stopRecording = useCallback(() => {
    if (isRecording) {
      setIsRecording(false);
      setIsLoading(true);
  
      if (audioWorkletNodeRef.current) {
        audioWorkletNodeRef.current.disconnect();
      }
      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
  
      handleAudioData();
      
      // Reset the audio buffer after handling the data
      audioBufferRef.current = [];
    }
  }, [isRecording]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer + 1;
          if (newTimer === 5) {
            setCanStop(true);
          }
          return newTimer;
        });
      }, 1000);
    } else {
      setTimer(0);
      setCanStop(false);
    }
    return () => clearInterval(interval);
  }, [isRecording]);


  useEffect(() => {
    async function initGuestId() {
      if (user === null && !guestId) {
        let storedGuestId = localStorage.getItem('guestId');
        if (!storedGuestId) {
          const fingerprint = await getFingerprint();
          storedGuestId = typeof fingerprint === 'string' ? fingerprint : fingerprint.hash;
          localStorage.setItem('guestId', storedGuestId);
        }
        setGuestId(storedGuestId);

        // Send the guestId to the server
        fetch('/api/set-guest-id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ guestId: storedGuestId }),
        });
      }
    }
    initGuestId();
  }, []); 

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
  
      const audioContext = new AudioContext();
      audioContextRef.current = audioContext;
  
      // Load and add the audio worklet module
      await audioContext.audioWorklet.addModule('/audio-processor.js');
  
      const sourceNode = audioContext.createMediaStreamSource(stream);
      sourceNodeRef.current = sourceNode;

      const compressor = audioContext.createDynamicsCompressor();
      compressor.threshold.setValueAtTime(-50, audioContext.currentTime);
      compressor.knee.setValueAtTime(40, audioContext.currentTime);
      compressor.ratio.setValueAtTime(12, audioContext.currentTime);
      compressor.attack.setValueAtTime(0, audioContext.currentTime);
      compressor.release.setValueAtTime(0.25, audioContext.currentTime);
  
      const workletNode = new AudioWorkletNode(audioContext, 'audio-processor');
      audioWorkletNodeRef.current = workletNode;
  
      workletNode.port.onmessage = (event) => {
        if (event.data.audioBuffer) {
          audioBufferRef.current.push(new Float32Array(event.data.audioBuffer));
        }
      };
  
      sourceNode.connect(workletNode);
      workletNode.connect(audioContext.destination);
  
      setIsRecording(true);
      setTimer(0);
      audioBufferRef.current = []; // Reset the audio buffer
    } catch (error) {
      console.error('Error starting recording:', error);
      toast.error('Failed to start recording. Please check your microphone permissions.');
    }
  };

  const handleAudioData = async () => {
    const audioData = audioBufferRef.current.reduce((acc, chunk) => {
      const tmp = new Float32Array(acc.length + chunk.length);
      tmp.set(acc, 0);
      tmp.set(chunk, acc.length);
      return tmp;
    }, new Float32Array());
  
    const aacBuffer = createWavBuffer(audioData, audioContextRef.current!.sampleRate);
    const audioBlob = new Blob([aacBuffer], { type: 'audio/webm' });
  
    const chunkSize = 4 * 1024 * 1024; // 4MB chunks

    const chunks: Blob[] = [];
    for (let i = 0; i < audioBlob.size; i += chunkSize) {
      chunks.push(audioBlob.slice(i, i + chunkSize));
    }
  
    toast.promise(
      (async () => {
        const responses = await Promise.all(chunks.map(async (chunk, index) => {
          const formData = new FormData();
          formData.append('audio', new File([chunk], `chunk_${index}.webm`, { type: 'audio/webm' }));
          formData.append('userId', user ? user.id : guestId);
          formData.append('chunkIndex', index.toString());
          formData.append('totalChunks', chunks.length.toString());
  
          const response = await fetch('/api/transcribe-chunk', {
            method: 'POST',
            body: formData,
          });
          console.log(formData)
          console.log(response);
          return response.json();
        }));
  
        const combinedTranscription = responses.map(r => r.transcription).join(' ');
        const noteResponse = await fetch('/api/create-note', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user ? user.id : guestId,
            transcription: combinedTranscription,
          }),
        });
  
        const noteData = await noteResponse.json();
        const newNoteEvent = new CustomEvent('newNote', { detail: noteData.note });
        window.dispatchEvent(newNoteEvent);
        setIsLoading(false);
        return 'Transcription completed successfully';
      })(),
      {
        loading: 'Transcribing...',
        success: (message) => message,
        error: () => {
          setIsLoading(false);
          return 'Transcription failed';
        },
      }
    );
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <DockWrapper direction="middle" className="fixed bg-white bottom-8 left-0 right-0 z-50 flex items-center justify-between bg-background px-4 py-2 shadow-lg sm:px-6 md:px-8 border-t border-border">
      <DockIcon>
        <nav className="flex items-center space-x-4">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" size={"icon"} className="rounded-full hover:bg-zinc-200">
                <Tag className="h-6 w-6 text-muted-foreground" />
                <span className="sr-only">Tags</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="ml-4 bg-white">
              <Tags user={user} />
            </PopoverContent>
          </Popover>
        </nav>
      </DockIcon>
      <DockIcon className="min-w-36 md:min-w-36">
      {!isRecording && !isLoading ? (
        <Button size="icon" className="rounded-full bg-black hover:bg-orange-600" onClick={startRecording}>
          <MicIcon className="h-6 w-6 text-white" />
          <span className="sr-only">Record Audio</span>
        </Button>
      ) : isRecording ? (
        <div className="flex items-center space-x-2">
          <Button 
            size="icon" 
            className={`rounded-full ${canStop ? 'bg-red-100 hover:bg-red-700' : 'bg-gray-300'} text-xs text-red-600`} 
            onClick={stopRecording}
            disabled={!canStop}
          >
            <StopIcon className={`h-6 w-6 ${canStop ? 'text-red-500' : 'text-gray-500'}`} />
            <span className="sr-only">Stop Recording</span>
          </Button>
          <span className="text-xs font-semibold">{formatTime(timer)} / 01:00</span>
        </div>
      ) : (
        <Button size="icon" className="rounded-full bg-gray-400" disabled>
          <Spinner className="h-6 w-6 text-white animate-spin" />
          <span className="sr-only">Transcribing</span>
        </Button>
      )}
      </DockIcon>
      <DockIcon>
      <nav className="flex items-center space-x-4">
        {user ? (
          <div>
            <Popover>
              <PopoverTrigger asChild>
              <Button variant="ghost" size="icon" className="rounded-full hover:bg-zinc-200">
                <Image alt="Users Image" className="h-7 rounded-full w-7 text-muted-foreground" src={user.profilePictureUrl} width={80} height={80} />
                <span className="sr-only">Profile</span>
              </Button>
              </PopoverTrigger>
              <PopoverContent className="mx-4 w-fit bg-white">
                <Profile />
              </PopoverContent>
            </Popover>
          </div>
        ) : (
          <Link href={signInUrl}>
            <Button variant="ghost" size="icon" className="rounded-full hover:bg-zinc-200">
              <User className="h-6 w-6 text-muted-foreground" />
              <span className="sr-only">Profile</span>
            </Button>
          </Link>
        )}
      </nav>
      </DockIcon>
    </DockWrapper>
  )
}

function Profile() {
  return (
    <div>
       <form action={signOut}>
          <Button variant={"ghost"} size="sm" className="rounded-full hover:bg-zinc-200" type="submit">
            Sign out
          </Button>
        </form>
    </div>
  )
}

function Tags({ user } : any) {
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState<TagType[]>([]);

  const fetchTags = async () => {
    if (!user) return; // Don't fetch if there's no user
    try {
      const response = await fetch(`/api/tags?userId=${user.id}`);
      if (response.ok) {
        const fetchedTags = await response.json();
        setTags(fetchedTags);
      }
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchTags();
    }
  }, [user]);

  const handleAddTag = () => {
    setIsAddingTag(true);
  };

  const handleConfirmTag = async () => {
    if (newTag.trim()) {
      try {
        const response = await fetch('/api/tags', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: newTag.trim(), userId: user.id }),
        });

        if (response.ok) {
          const createdTag = await response.json();
          setTags([...tags, createdTag]);
          toast.success('Tag created successfully and will automatically be used in future transcriptions.');
          setIsAddingTag(false);
          setNewTag('');
        } else {
          console.error('Failed to create tag');
        }
      } catch (error) {
        console.error('Error creating tag:', error);
      }
    }
  };

  const handleCancelTag = () => {
    setIsAddingTag(false);
    setNewTag('');
  };

  const handleDeleteTag = async (tagId: String) => {
    try {
      const response = await fetch(`/api/tags/${tagId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        toast.success('Tag deleted successfully and will not be used anymore.');
        setTags(tags.filter(tag => tag.id !== tagId.toString()));
      } else {
        toast.error('Failed to delete tag');
        console.error('Failed to delete tag');
      }
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  return (
    <div className="space-y flex flex-col">
      <div className="flex items-center justify-between">
        <h2 className="font-medium text-black">Your Custom Tags</h2>
        {!isAddingTag && user && (
          <Button variant="ghost" size="icon" className="rounded-full hover:bg-zinc-200" onClick={handleAddTag}>
            <PlusCircle className="h-6 w-6 text-muted-foreground" />
            <span className="sr-only">Add Tag</span>
          </Button>
        )}
      </div>
      {isAddingTag && (
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Enter new tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            className="flex-grow"
          />
          <Button variant="ghost" size="icon" className="rounded-full hover:bg-green-200" onClick={handleConfirmTag}>
            <Check className="h-5 w-5 text-green-600" />
          </Button>
          <Button variant="ghost" size="icon" className="rounded-full hover:bg-red-200" onClick={handleCancelTag}>
            <X className="h-5 w-5 text-red-600" />
          </Button>
        </div>
      )}
      <motion.div layout className="flex space-y py-4 flex-wrap gap-2">
        <AnimatePresence>
        {user ? tags.map((tag) => {
          const bgColor = hashStringToColor(tag.name);
          const textColor = getDarkerColor(bgColor);
          return (  
              <motion.span 
                key={tag.id} 
                className="bg-gray-200 px-2 py-1 rounded-full text-sm flex items-center font-medium" 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{ backgroundColor: bgColor, color: textColor }}
              >
                {tag.name}
                <button 
                  onClick={() => handleDeleteTag(tag.id)} 
                  className="ml-2 p-1 rounded-full hover:bg-red-200 transition-colors"
                >
                  <X className="h-3 w-3 text-red-600" />
                </button>
              </motion.span>
          )}) : <div>
              <p className="text-xs my-2 text-gray-500">You need to sign in to add custom tags.</p>
            </div>}
          </AnimatePresence>
      </motion.div>
      <div>
        <p className="text-xs mt-2 text-gray-500">Tags will be automatically applied to notes in future transcriptions.</p>
      </div>
    </div>
  )
}

function MicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z" />
      <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
      <line x1="12" x2="12" y1="19" y2="22" />
    </svg>
  )
}

function StopIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="14" height="14" x="5" y="5" rx="2" ry="2" />
    </svg>
  )
}

// Helper function to create WAV buffer
function createWavBuffer(audioData: Float32Array, sampleRate: number): ArrayBuffer {
  const numChannels = 1;
  const bitsPerSample = 16;
  const bytesPerSample = bitsPerSample / 8;
  const blockAlign = numChannels * bytesPerSample;
  const byteRate = sampleRate * blockAlign;
  const dataSize = audioData.length * bytesPerSample;
  const buffer = new ArrayBuffer(44 + dataSize);
  const view = new DataView(buffer);

  // WAV header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + dataSize, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, byteRate, true);
  view.setUint16(32, blockAlign, true);
  view.setUint16(34, bitsPerSample, true);
  writeString(view, 36, 'data');
  view.setUint32(40, dataSize, true);

  // Audio data
  floatTo16BitPCM(view, 44, audioData);

  return buffer;
}
function writeString(view: DataView, offset: number, string: string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function floatTo16BitPCM(output: DataView, offset: number, input: Float32Array) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
  }
}
