import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@workos-inc+authkit-nextjs@0.10.0_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__re_7puispnyr3zfhe4dezh3n2yu5m/node_modules/@workos-inc/authkit-nextjs/dist/cjs/min-max-button.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@workos-inc+authkit-nextjs@0.10.0_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__re_7puispnyr3zfhe4dezh3n2yu5m/node_modules/@workos-inc/authkit-nextjs/dist/cjs/provider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.3.1_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/sonner@1.5.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Dock"] */ "/vercel/path0/src/components/dock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuItem"] */ "/vercel/path0/src/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
